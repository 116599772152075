<template>
  <div>
    <label class="toggle" :class="className">
      <input
        class="toggle__checkbox"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
      />
      <div class="toggle__switch"></div>
      <span class="toggle__label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    label: [String, Number],
    disabled: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
  },
  computed: {
    className() {
      return {
        "toggle--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.toggle {
  cursor: pointer;
  display: inline-block;
  &__switch {
    display: inline-block;
    background: var(--white);
    border-radius: 16px;
    border: 1px solid var(--background--disabled);
    width: 36px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.3s ease-out;
  }
  &__switch:before,
  &__switch:after {
    content: "";
  }
  &__switch:before {
    display: block;
    background: var(--color--disabled);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.25s;
  }

  &__checkbox:checked + &__switch {
    background: var(--background--btn--red);
    border: 1px solid var(--background--btn--red);
  }
  &__checkbox:checked + &__switch:before {
    background: var(--white);
    box-shadow: 0px 1px 2px rgba(17, 24, 39, 0.06),
      0px 1px 3px rgba(17, 24, 39, 0.1);
    left: 17px;
  }
  &__checkbox {
    position: absolute;
    visibility: hidden;
  }

  &__label {
    margin-left: 10px;
    position: relative;
    top: 2px;
    line-height: 1.7rem;
    font-size: 1.4rem;
  }
  & input:disabled + toggle__switch:before {
    background: var(--background--disabled);
  }

  & input:checked:disabled + toggle__switch:before {
    background: var(--white);
  }
  &--disabled {
    pointer-events: none;
  }
}
</style>
